import { Theme, makeStyles, createStyles } from '@material-ui/core';

const styles = (theme: Theme) =>
    createStyles({
        copyrightText: {
            color: theme.palette.custom.white27,
            fontSize: theme.typography.pxToRem(10),
        },
    });

export default makeStyles(styles);
