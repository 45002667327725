import React from 'react';
import { Typography } from '@material-ui/core';
import { Link } from 'atoms';
import useStyles from './styles';

const CopyrightNotice = (props: CopyrightNoticePropTypes) => {
    const classes = useStyles();

    return (
        <Typography align={'center'} className={classes.copyrightText}>
            Copyright © Jenasnelling.com 2020. All rights reserved. Designed & developed
            with 💪🏽 by{' '}
            <Link variant={'naked'} external href={'https://github.com/Jmeza081'}>
                <Typography
                    component={'span'}
                    display={'inline'}
                    className={classes.copyrightText}
                >
                    Jesse Meza
                </Typography>
            </Link>
            {' & '}
            <Link variant={'naked'} external href={'https://www.instagram.com/nieky.m4a/'}>
                <Typography
                    component={'span'}
                    display={'inline'}
                    className={classes.copyrightText}
                >
                    Nieky Allen
                </Typography>
            </Link>
            .
        </Typography>
    );
};

export type CopyrightNoticePropTypes = {};

export default CopyrightNotice;
