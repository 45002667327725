import { Theme, makeStyles, createStyles } from '@material-ui/core';

const styles = (theme: Theme) =>
    createStyles({
        primaryDivider: {
            height: '3px',
            backgroundColor: theme.palette.primary.main,
        },
    });

export default makeStyles(styles);
