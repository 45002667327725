import React from 'react';
import { Divider, DividerProps, useTheme } from '@material-ui/core';
import useStyles from './styles';

const Separator = (props: SeparatorPropTypes) => {
    const { verticalSpacing, ...rest } = props;

    const classes = useStyles();
    const theme = useTheme();

    return (
        <div
            style={{
                padding: theme.spacing(verticalSpacing, 0),
            }}
        >
            <Divider
                {...rest}
                classes={{
                    root: classes.primaryDivider,
                }}
            />
        </div>
    );
};

Separator.defaultProps = {
    verticalSpacing: 1,
};

export interface SeparatorPropTypes extends DividerProps {
    verticalSpacing?: number;
}

export default Separator;
