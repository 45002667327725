import { Theme, makeStyles, createStyles } from '@material-ui/core';

const styles = (theme: Theme) =>
    createStyles({
        masonryGrid: {
            columnCount: 3,
            columnGap: theme.spacing(2),
            [theme.breakpoints.down('md')]: {
                columnCount: 2,
                margin: theme.spacing(0, 2),
            },
            [theme.breakpoints.down('sm')]: {
                columnCount: 1,
                margin: theme.spacing(0, 2),
            },
        },
        masonryGridItem: {
            width: '100%',
            height: 'auto',
            objectFit: 'cover',
            marginBottom: theme.spacing(2),
        },
        tallMasonrySkeletonBrickItem: {
            height: '600px',
            width: '100%',
            marginBottom: theme.spacing(2),
            breakInside: 'avoid-column',
        },
        shortMasonrySkeletonBrickItem: {
            height: '300px',
            width: '100%',
            marginBottom: theme.spacing(2),
            breakInside: 'avoid-column',
        },
    });

export default makeStyles(styles);
