import { Theme, makeStyles, createStyles } from '@material-ui/core';

const styles = (theme: Theme) =>
    createStyles({
        scrollBoxContainer: {
            width: '12vw',
            [theme.breakpoints.down('sm')]: {
                width: '30vw',
            },
            opacity: '50%',
            padding: theme.spacing(0, 2),
        },
        scrollableRowContainer: {
            padding: theme.spacing(2, 0),
        },
        beltContainer: {
            display: 'flex',
            flexDirection: 'row',
        },
        brandImageContainer: {
            position: 'relative',
            paddingTop: '100%',
        },
        brandImage: {
            position: 'absolute',
            objectFit: 'contain',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
        },
    });

export default makeStyles(styles);
