import { Theme, makeStyles, createStyles } from '@material-ui/core';

const styles = (theme: Theme) =>
    createStyles({
        dialog: {
            minHeight: '700px',
        },
        card: {
            backgroundColor: theme.palette.custom.surfaces.aboveSurface,
            borderRadius: theme.spacing(1),
        },
        dialogeTitleContainer: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            padding: theme.spacing(3),
        },
        dialogContent: {
            padding: 0,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            backgroundColor: '#000',
            '&:first-child': {
                paddingTop: 0,
            },
        },
        mobileTitleContainer: {
            padding: theme.spacing(2),
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-bewteen',
        },
        closeButtonContainer: {
            position: 'absolute',
            top: theme.spacing(4),
            right: 0,
        },
        closeButtonMobile: {
            backgroundColor: `rgba(255, 255, 255, 0.08)`,
        },
        closeButton: {
            height: theme.typography.pxToRem(70),
            width: theme.typography.pxToRem(70),
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
        },
        dialogActionsContainer: {
            padding: theme.spacing(2),
            minHeight: '64px',
        },
    });

export default makeStyles(styles);
