import React from 'react';
import useStyles from './styles';
import { Play } from 'react-feather';
import { Zoom, Fade } from '@material-ui/core';

const PlayButton = (props: PlayButtonPropTypes) => {
    const classes = useStyles();

    return (
        <Fade in>
            <div className={classes.root}>
                <Play className={classes.playIcon} />
            </div>
        </Fade>
    );
};

export type PlayButtonPropTypes = {};

export default PlayButton;
