import { Theme, makeStyles, createStyles } from '@material-ui/core';

const styles = (theme: Theme) =>
    createStyles({
        root: {
            color: theme.palette.custom.white16,
        },

        // Size Variant
        large: {
            fontSize: theme.typography.pxToRem(32),
        },
        medium: {
            fontSize: theme.typography.pxToRem(28),
        },
        small: {
            fontSize: theme.typography.pxToRem(18),
        },
    });

export default makeStyles(styles);
