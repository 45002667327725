import { Theme, makeStyles, createStyles } from '@material-ui/core';
import { ASPECT_RATIOS } from 'config/Media';

const styles = (theme: Theme) =>
    createStyles({
        root: {
            margin: 0,
            [theme.breakpoints.down('md')]: {
                margin: theme.spacing(0, 2),
            },
        },
        gridHeader: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
            paddingBottom: theme.spacing(1),
        },
        capitalizeGridTitle: {
            textTransform: 'capitalize',
        },
        /**
         * See: https://material-ui.com/components/grid/#negative-margin
         * For details on why this is required
         */
        hideGridOverflow: {
            overflow: 'hidden',
        },
        teaserSkeleton: {
            position: 'relative',
            overflow: 'hidden',
            paddingTop: ASPECT_RATIOS.videos.teasers.standard,
        },
        skeletalImage: {
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
        },
    });

export default makeStyles(styles);
