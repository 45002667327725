import { Theme, makeStyles, createStyles } from '@material-ui/core';
import { ASPECT_RATIOS } from 'config/Media';

const styles = (theme: Theme) =>
    createStyles({
        root: {
            '&:hover': {
                cursor: 'pointer',
            },
            '&:selected': {
                outline: `2px solid ${theme.palette.primary.main}`,
            },
        },
        container: {
            position: 'relative',
            overflow: 'hidden',
            paddingTop: ASPECT_RATIOS.images.teasers.standard,
        },
        thumbnailImage: {
            position: 'absolute',
            objectFit: 'cover',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            zIndex: 0,
        },
        skeletalImage: {
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
        },
        videoTitle: {
            fontFamily: 'Chivo, sans-serif',
            fontWeight: 'bold',
            lineHeight: '1.5rem',
        },
        videoDescription: {
            lineHeight: 'normal',
        },
        contentDescriptionContainer: {
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            zIndex: 1,
            backgroundColor: 'rgba(31, 31, 31, 0.4)',
            backdropFilter: 'blur(7px)',
            [theme.breakpoints.down('sm')]: {
                backdropFilter: 'none',
            },
            padding: theme.spacing(1),
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-end',
        },
    });

export default makeStyles(styles);
