import { Theme, makeStyles, createStyles } from '@material-ui/core';

const styles = (theme: Theme) =>
    createStyles({
        root: {
            backgroundColor: theme.palette.custom.white04,
            borderRadius: '10px',
        },
        outlined: {
            borderRadius: '10px',
        },
        disabled: {
            backgroundColor: theme.palette.custom.surfaces.atSurface,
        },
    });

export default makeStyles(styles);
