import React from 'react';
import Link from 'atoms/Link';
import Logo from '../../../public/static/img/jena_snelling_yellow.svg';
import { ROUTES } from 'config/Nav';
import useStyles from './styles';
import { LOGO_GIF } from 'config/Media';

const BrandLogo = ({ size, asLink, variant }: BrandLogoPropTypes) => {
    const classes = useStyles();
    const renderLogo = () => {
        if (variant === 'animated') {
            return (
                <img
                    src={LOGO_GIF}
                    className={classes[size]}
                    alt={'Jena Snelling Logo'}
                />
            );
        } else if (variant === 'static') {
            return <Logo className={classes[size]} />;
        } else {
            console.error(
                `unknown logo variant prop "${variant}" provided to BrandLogo component.`,
            );
            return null;
        }
    };

    if (asLink)
        return (
            <Link variant={'wrapper'} href={ROUTES.HOME.to}>
                {renderLogo()}
            </Link>
        );

    return renderLogo();
};

BrandLogo.defaultProps = {
    asLink: false,
    variant: 'animated',
};

export type BrandLogoPropTypes = {
    size: 'small' | 'medium' | 'large';
    asLink?: boolean;
    variant?: 'animated' | 'static';
};

export default BrandLogo;
