import { Theme, makeStyles, createStyles } from '@material-ui/core';

const styles = (theme: Theme) =>
    createStyles({
        navLinkDefault: {
            color: theme.palette.custom.darkGrey,
            fontSize: theme.typography.pxToRem(16),
            '&:hover': {
                color: theme.palette.primary.main,
                textDecoration: 'none',
            },
            '&:not(:first-child)': {
                marginLeft: theme.spacing(3),
            },
        },
        activeLink: {
            color: theme.palette.primary.main,
        },
    });

export default makeStyles(styles);
