import React from 'react';
import Chip, { ChipProps } from '@material-ui/core/Chip';
import useStyles from './styles';

const InfoChip = (props: ChipProps) => {
    const classes = useStyles();

    return (
        <Chip
            classes={{
                root: classes.infoChip,
                label: classes.infoChipLabel,
            }}
            {...props}
        />
    );
};

export default InfoChip;
