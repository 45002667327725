import React from 'react';
import { Avatar as MuiAvatar, AvatarProps } from '@material-ui/core';
import clsx from 'clsx';
import useStyles from './styles';

const Avatar = React.forwardRef<HTMLDivElement, AvatarPropTypes>(
    (props, forwardedRef) => {
        const { disableAnimation, ...rest } = props;

        const classes = useStyles();
        return (
            <div ref={forwardedRef} className={classes.root}>
                <MuiAvatar
                    className={clsx(classes.avatar, {
                        [classes.animateAvatar]: !disableAnimation,
                    })}
                    {...rest}
                />
            </div>
        );
    },
);

Avatar.defaultProps = {
    disableAnimation: false,
};

export interface AvatarPropTypes extends AvatarProps {
    disableAnimation?: boolean;
}

export default Avatar;
