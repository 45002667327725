import React from 'react';
import {
    Dialog,
    DialogContent,
    DialogProps,
    DialogTitle,
    IconButton,
    Typography,
    useMediaQuery,
    useTheme,
} from '@material-ui/core';
import { ToolTip } from 'atoms';
import { Formik, FormikProps } from 'formik';
import { X } from 'react-feather';
import { object as yupObject, string } from 'yup';
import ContactForm from './ContactForm';
import useStyles from './styles';

const ContactDialog = (props: ContactDialogPropTypes) => {
    const classes = useStyles();
    const { open, onClose, onSubmitContactForm, ...rest } = props;

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const validationSchema = yupObject({
        name: string().required('Please provide your full name'),
        email: string().email('Invalid email address').required('this field is required'),
        subject: string().required('this field is required'),
        body: string().required('this field is required'),
    });

    const initialValues: ContactFormValues = {
        name: '',
        email: '',
        subject: '',
        body: '',
    };

    /**
     * Wrap the onSubmit function and pass onClose handler
     */
    const handleSubmitContactForm = (
        values: ContactFormValues,
        formikHelpers: FormikProps<ContactFormValues>,
    ) => {
        onSubmitContactForm(values, formikHelpers, onClose);
    };

    const renderDialogeHeader = (isSubmitting: boolean) => {
        return (
            <DialogTitle disableTypography className={classes.dialogeTitleContainer}>
                <div>
                    <Typography variant={'h4'} color={'textPrimary'}>
                        Let's Chat
                    </Typography>
                    <Typography variant={'body2'} color={'textSecondary'}>
                        Let's work on something new!
                    </Typography>
                </div>
                <div className={classes.closeButton}>
                    <ToolTip title={'Close Contact Form'} arrow>
                        <IconButton
                            disabled={isSubmitting}
                            onClick={(event: any) => onClose(event, 'escapeKeyDown')}
                        >
                            <X />
                        </IconButton>
                    </ToolTip>
                </div>
            </DialogTitle>
        );
    };

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmitContactForm}
            validateOnChange
        >
            {(props) => (
                <Dialog
                    open={open}
                    onClose={onClose}
                    aria-labelledby={`contact-form-dialog`}
                    fullWidth
                    PaperProps={{
                        className: classes.card,
                    }}
                    scroll={'body'}
                    fullScreen={isMobile}
                    disableEscapeKeyDown={props.isSubmitting}
                    disableBackdropClick={props.isSubmitting}
                    {...rest}
                >
                    <DialogContent>
                        {renderDialogeHeader(props.isSubmitting)}
                        <ContactForm handleDialogClose={onClose} {...props} />
                    </DialogContent>
                </Dialog>
            )}
        </Formik>
    );
};

export interface ContactDialogPropTypes extends Omit<DialogProps, 'children'> {
    onSubmitContactForm: Function;
}

export type ContactFormValues = {
    name: string;
    email: string;
    subject: string;
    body: string;
};

export default ContactDialog;
