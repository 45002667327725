export const ASPECT_RATIOS = {
    videos: {
        embeds: {
            square: '100%', // Perfect square 1:1
            standard: '43.75%', // 1280 x 560 to fit modal dimensions,
            featured: '62.5%', // 3200 x 2000 to fit modal dimensions,
            banner: '62.5%', // 3200 x 2000 pixels
        },
        teasers: {
            square: '100%', // Perfect square 1:1
            standard: '56.25%', //16:9,
            featured: '62.5%', // 3200 x 2000 to fit modal dimensions,
            banner: '62.5%', // 3200 x 2000 pixels
        },
    },
    images: {
        teasers: {
            standard: '67.08%', // roughly 160 x 240 but tweaked a bit
        },
    },
};

export const CLOUDINARY_PREFIX = 'https://res.cloudinary.com/jenasnelling/image/upload/';

export const HEADSHOT =
    'https://res.cloudinary.com/jenasnelling/image/upload/c_scale,w_500/v1685491524/Jts2023_pgdu6z.png';

export const LOGO_GIF = 'https://res.cloudinary.com/jenasnelling/image/upload/v1612316278/JTS_Glow_tiny_rmnr0x.gif';

export const RESUME_DOWNLOAD_LINK = '/static/JenaSnelling2023.pdf';
