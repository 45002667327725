import React from 'react';
import { VideoEmbed } from 'atoms';
import { VideoEmbedPropTypes } from 'atoms/VideoEmbed/VideoEmbed';
import ContentModal, { ContentModalPropTypes } from 'molecules/ContentModal/ContentModal';

const VideoPlayerModal = (props: VideoPlayerModalPropTypes) => {
    const { videoUrl, aspectRatio, ...rest } = props;

    return (
        <ContentModal {...rest}>
            <VideoEmbed aspectRatio={aspectRatio} videoUrl={videoUrl} />
        </ContentModal>
    );
};

export interface VideoPlayerModalPropTypes
    extends ContentModalPropTypes,
        VideoEmbedPropTypes {}

export default VideoPlayerModal;
