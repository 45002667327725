import React from 'react';
import { Typography } from '@material-ui/core';
import { NavLink } from 'types/navigation';
import { Link } from 'atoms';
import useStyles from './styles';

const FooterLinkGroup = (props: FooterLinkGroupPropTypes) => {
    const { groupHeader, links } = props;
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <div className={classes.linkGroupHeader}>
                <Typography variant={'subtitle1'} color={'textPrimary'}>
                    {groupHeader}
                </Typography>
            </div>
            {links.map((link) => (
                <div key={`${link.name}-footer-link`} className={classes.linkGroupItem}>
                    <Link variant={'footer'} href={link.to} external={link.external}>
                        {link.name}
                    </Link>
                </div>
            ))}
        </div>
    );
};

export type FooterLinkGroupPropTypes = {
    groupHeader: string;
    links: Array<NavLink>;
};

export default FooterLinkGroup;
