import { Theme, makeStyles, createStyles } from '@material-ui/core';

const styles = (theme: Theme) =>
    createStyles({
        infoChip: {
            backgroundColor: theme.palette.common.black,
            borderRadius: 0,
        },
        infoChipLabel: {
            color: theme.palette.custom.white70,
            textTransform: 'uppercase',
        },
    });

export default makeStyles(styles);
