import React from 'react';
import { IconButton } from '@material-ui/core';
import {
    Facebook,
    Help,
    Instagram,
    Linkedin,
    Soundcloud,
    Vimeo,
    Youtube,
} from 'mdi-material-ui';
import { SocialMediaLink } from 'types/socialMedia';
import { size } from 'types/size';
import useStyles from './styles';

const SocialMediaLinkComponent = (props: SocialMediaLinkPropTypes) => {
    const classes = useStyles();

    const { variant, iconSize, href } = props;

    const renderSocialMediaLink = () => {
        let icon;
        const iconProps = {
            className: classes[iconSize],
        };
        switch (variant) {
            case 'instagram':
                icon = <Instagram {...iconProps} />;
                break;
            case 'facebook':
                icon = <Facebook {...iconProps} />;
                break;
            case 'vimeo':
                icon = <Vimeo {...iconProps} />;
                break;
            case 'youtube':
                icon = <Youtube {...iconProps} />;
                break;
            case 'soundcloud':
                icon = <Soundcloud {...iconProps} />;
                break;
            case 'linkedin':
                icon = <Linkedin {...iconProps} />;
                break;
            default:
                console.warn(
                    `NORAD: Unknown SocialMedia variant "${variant}" passed to component.`,
                );
                icon = <Help {...iconProps} />;
                break;
        }

        return (
            <IconButton
                component={'a'}
                classes={{
                    root: classes.root,
                }}
                href={href}
                target={'_blank'}
            >
                {icon}
            </IconButton>
        );
    };

    return renderSocialMediaLink();
};

SocialMediaLinkComponent.defaultProps = {
    iconSize: 'large',
};

export interface SocialMediaLinkPropTypes extends SocialMediaLink {
    iconSize?: size;
}

export default SocialMediaLinkComponent;
