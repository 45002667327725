import React from 'react';
import { ChevronLeft } from 'mdi-material-ui';
import { Typography } from '@material-ui/core';
import { useRouter } from 'next/router';
import useStyles from './styles';

const BackButton = (props: BackButtonPropTypes) => {
    const { children } = props;

    const classes = useStyles();
    const router = useRouter();

    const onBackButtonClick = () => {
        router.back();
    };

    return (
        <div onClick={onBackButtonClick} className={classes.root}>
            <ChevronLeft className={classes.backButtonText} />
            <Typography variant={'body1'} className={classes.backButtonText}>
                {children}
            </Typography>
        </div>
    );
};

export type BackButtonPropTypes = {
    children: React.ReactNode;
};

export default BackButton;
