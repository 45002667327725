import { createStyles, makeStyles, Theme } from '@material-ui/core';

const styles = (theme: Theme) =>
    createStyles({
        root: {
            backgroundColor: theme.palette.common.black,
            position: 'relative',
        },
        childContainer: {
            position: 'absolute',
            width: '100%',
            height: '100%',
        },
        imageRoot: {
            width: '100%',
            height: '100%',
            objectFit: 'cover',
            position: 'absolute',
        },
    });

export default makeStyles(styles);
