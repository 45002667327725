import React, { MouseEvent } from 'react';
import { Typography } from '@material-ui/core';
import NextImg from 'next/image';
import { PlayButton } from 'atoms';
import truncate from 'lodash/truncate';
import clsx from 'clsx';
import useStyles from './styles';

const VideoThumbnail = (props: VideoThumbnailPropTypes) => {
    const {
        thumbnailSrc,
        title,
        description,
        onClick,
        alwaysShowPlayButton,
        aspectRatio,
    } = props;
    const classes = useStyles();

    const [displayShowButton, setDisplayShowButton] = React.useState(
        alwaysShowPlayButton,
    );

    const hidePlayButton = () => {
        setDisplayShowButton(false);
    };

    const showPlayButton = () => {
        setDisplayShowButton(true);
    };

    return (
        <div
            onClick={onClick}
            onMouseEnter={alwaysShowPlayButton ? null : showPlayButton}
            onMouseLeave={alwaysShowPlayButton ? null : hidePlayButton}
            className={classes.root}
            tabIndex={0}
        >
            <div
                className={clsx(
                    classes.container,
                    classes[`${aspectRatio}AspectRatio` as keyof typeof classes],
                )}
            >
                <NextImg
                    src={thumbnailSrc}
                    alt={title}
                    className={classes.thumbnailImage}
                    layout={'fill'}
                />
                {displayShowButton ? (
                    <div className={classes.playButtonContainer}>
                        <PlayButton />
                    </div>
                ) : null}
            </div>

            {title ? (
                <div className={classes.videoTitleContainer}>
                    <Typography
                        variant={'subtitle1'}
                        color={'primary'}
                        className={classes.videoTitle}
                    >
                        {title}
                    </Typography>
                </div>
            ) : null}
            {description ? (
                <Typography
                    variant={'body2'}
                    color={'textSecondary'}
                    className={classes.videoDescription}
                >
                    {truncate(description, {
                        length: 119,
                    })}
                </Typography>
            ) : null}
        </div>
    );
};

VideoThumbnail.defaultProps = {
    alwaysShowPlayButton: false,
    aspectRatio: 'teaser',
};

export type VideoThumbnailPropTypes = {
    onClick: (event: MouseEvent<HTMLDivElement>) => void;
    thumbnailSrc: string;
    title?: string;
    description?: string;
    alwaysShowPlayButton?: boolean;
    aspectRatio: 'teaser' | 'featured' | 'banner';
};

export default VideoThumbnail;
