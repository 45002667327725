import { Theme, makeStyles, createStyles } from '@material-ui/core';
import { ASPECT_RATIOS } from 'config/Media';

const styles = (theme: Theme) =>
    createStyles({
        root: {
            '&:hover': {
                cursor: 'pointer',
            },
            '&:selected': {
                outline: `2px solid ${theme.palette.primary.main}`,
            },
        },
        container: {
            position: 'relative',
            overflow: 'hidden',
        },
        teaserAspectRatio: {
            paddingTop: ASPECT_RATIOS.videos.teasers.standard,
        },
        featuredAspectRatio: {
            paddingTop: ASPECT_RATIOS.videos.teasers.featured,
            objectFit: 'fill',
        },
        bannerAspectRatio: {
            paddingTop: ASPECT_RATIOS.videos.teasers.banner,
            objectFit: 'fill',
        },
        thumbnailImage: {
            position: 'absolute',
            objectFit: 'cover',
            top: 0,
            left: 0,
            width: '100%',
            height: 'auto',
            zIndex: 0,
        },
        videoTitleContainer: {
            padding: theme.spacing(1, 0),
        },
        videoTitle: {
            fontFamily: 'Chivo, sans-serif',
            fontWeight: 'bold',
            lineHeight: '1.5rem',
        },
        videoDescription: {
            lineHeight: 'normal',
        },
        playButtonContainer: {
            position: 'absolute',
            top: '50%',
            left: '50%',
            marginTop: '-20px',
            marginLeft: '-20px',
            zIndex: 1,
        },
    });

export default makeStyles(styles);
