import React from 'react';
import { Typography, Box } from '@material-ui/core';
import { Separator, Button, BaseLink } from 'atoms';
import { ROUTES } from 'config/Nav';
import useStyles from './styles';

const CallToAction = (props: CallToActionPropTypes) => {
    const { callToActionButtonText, onCallToActionButtonClick } = props;
    const classes = useStyles();

    let buttonProps: any = {};
    if (onCallToActionButtonClick) {
        buttonProps.onClick = onCallToActionButtonClick;
    } else {
        buttonProps.href = ROUTES.COLLAB.to;
    }

    return (
        <div className={classes.imageBackground}>
            <div className={classes.imageFilterContainer}>
                <div className={classes.ctaContainer}>
                    <Typography variant={'h5'}>there's something missing...</Typography>
                    <div className={classes.dividerContainer}>
                        <Separator verticalSpacing={2} />
                    </div>
                    <Typography variant={'body1'} align={'center'}>
                        Our project ! Let’s connect !
                    </Typography>
                    <Box paddingTop={3}>
                        <Button
                            component={onCallToActionButtonClick ? 'button' : BaseLink}
                            variant={'outlined'}
                            color={'secondary'}
                            className={classes.disabledLinkStyles}
                            {...buttonProps}
                        >
                            {callToActionButtonText}
                        </Button>
                    </Box>
                </div>
            </div>
        </div>
    );
};

CallToAction.defaultProps = {
    callToActionButtonText: 'Collaborate',
};

export type CallToActionPropTypes = {
    callToActionButtonText?: string;
    onCallToActionButtonClick?: Function;
};

export default CallToAction;
