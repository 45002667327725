import { Theme, makeStyles, createStyles } from '@material-ui/core';

const styles = (theme: Theme) =>
    createStyles({
        imageBackground: {
            backgroundImage: `url("https://images.unsplash.com/photo-1551332772-f6bc95b9ed1f?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=934&q=80")`,
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            objectFit: 'fill',
            height: '340px',
            [theme.breakpoints.down('sm')]: {
                height: '250px',
            },
            width: '100%',
        },
        imageFilterContainer: {
            width: '100%',
            height: '100%',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: 'rgba(6, 6, 6, 0.8)',
        },
        ctaContainer: {
            width: '100%',
            height: '100%',
            maxWidth: '350px',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
        },
        dividerContainer: {
            width: '100%',
        },
        disabledLinkStyles: {
            '&:hover': {
                textDecoration: 'none',
            },
        },
    });

export default makeStyles(styles);
