import { Theme, makeStyles, createStyles } from '@material-ui/core';

const styles = (theme: Theme) =>
    createStyles({
        card: {
            backgroundColor: theme.palette.custom.surfaces.aboveSurface,
            borderRadius: theme.spacing(1),
            maxWidth: '740px',
        },
        dialogeTitleContainer: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            padding: theme.spacing(1, 0),
        },
        closeButton: {
            height: theme.typography.pxToRem(70),
            width: theme.typography.pxToRem(70),
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'flex-end',
        },
        dialogActionsContainer: {
            padding: theme.spacing(2, 0),
        },
        formContainer: {
            display: 'flex',
            flexDirection: 'column',
            '& > *': {
                margin: theme.spacing(2, 0),
            },
        },
        sendingButtonTextContainer: {
            position: 'relative',
        },
        circularProgress: {
            position: 'absolute',
            top: '5px',
            left: '50%',
            marginLeft: '-8px',
        },
    });

export default makeStyles(styles);
