import React from 'react';
import clsx from 'clsx';
import useStyles from './styles';
import BaseLink from 'atoms/BaseLink';
import { NextComposedPropTypes } from 'atoms/BaseLink/BaseLink';

const NavLink = (props: NavLinkPropTypes) => {
    const classes = useStyles();

    const { label, ...rest } = props;

    return (
        <BaseLink
            className={classes.navLinkDefault}
            activeClassName={classes.activeLink}
            {...rest}
        >
            {label}
        </BaseLink>
    );
};

export type NavLinkPropTypes = NextComposedPropTypes & {
    label: string;
    href: string;
};

export default NavLink;
