import React from 'react';
import { VideoEmbed } from 'atoms';
import { VideoEmbedPropTypes } from 'atoms/VideoEmbed/VideoEmbed';
import { VideoThumbnail } from 'molecules';

const FeaturedVideo = (props: FeaturedVideoPropTypes) => {
    const { videoUrl, thumbnailSrc, asBanner } = props;

    const [showThumbnail, setShowThumbnail] = React.useState(true);

    return showThumbnail ? (
        <VideoThumbnail
            aspectRatio={asBanner ? 'banner' : 'featured'}
            alwaysShowPlayButton
            thumbnailSrc={thumbnailSrc}
            onClick={() => {
                setShowThumbnail(false);
            }}
        />
    ) : (
        <VideoEmbed videoUrl={videoUrl} aspectRatio={'featured'} />
    );
};

FeaturedVideo.defaultProps = {
    asBanner: false,
};

export interface FeaturedVideoPropTypes extends Omit<VideoEmbedPropTypes, 'aspectRatio'> {
    thumbnailSrc: string;
    asBanner?: boolean;
}

export default FeaturedVideo;
