import React, { MouseEvent } from 'react';
import {
    AppBar as MuiAppBar,
    AppBarProps,
    Box,
    Hidden,
    IconButton,
    Slide,
    Toolbar,
    useMediaQuery,
    useScrollTrigger,
    useTheme,
} from '@material-ui/core';
import { BrandLogo, NavLink } from 'atoms';
import { ROUTES } from 'config/Nav';
import { Menu } from 'react-feather';
import clsx from 'clsx';
import useStyles from './styles';

const AppBar = (props: AppBarPropTypes) => {
    const { onMobileMenuClick } = props;
    const classes = useStyles();

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    // const trigger = useScrollTrigger({
    //     disableHysteresis: true,
    //     threshold: 150,
    // });

    const renderMenuButton = () => {
        return (
            <Hidden mdUp>
                <IconButton onClick={onMobileMenuClick}>
                    <Menu className={classes.mobileMenuTint} />
                </IconButton>
            </Hidden>
        );
    };

    const renderNearTopNav = () => {
        return (
            <Hidden mdUp>
                <div className={classes.headerNavContainer}>
                    <BrandLogo size={'large'} asLink />
                    <Box paddingTop={2}>{renderNavLinks()}</Box>
                </div>
            </Hidden>
        );
    };

    const renderNavLinks = () => {
        return Object.values(ROUTES).map(({ to, name }, index) => (
            <NavLink key={index} href={to} label={name} />
        ));
    };

    const renderFixedNavBar = () => {
        return (
            <MuiAppBar elevation={0} className={classes.appBar}>
                <Toolbar
                    classes={{
                        root: classes.root,
                    }}
                    disableGutters
                >
                    <div className={clsx(classes.flexRowBase, classes.toolbarLeft)}>
                        <BrandLogo size={'medium'} asLink />
                    </div>
                    <div className={clsx(classes.flexRowBase, classes.toolbarRight)}>
                        {renderMenuButton()}
                        <Hidden smDown>{renderNavLinks()}</Hidden>
                    </div>
                </Toolbar>
            </MuiAppBar>
        );
    };

    return (
        <React.Fragment>
            {/**in={trigger} */}
            {isMobile ? <Slide in>{renderFixedNavBar()}</Slide> : renderFixedNavBar()}

            {/* {renderNearTopNav()} */}
        </React.Fragment>
    );
};

export type AppBarPropTypes = AppBarProps & {
    onMobileMenuClick: (event: MouseEvent) => void;
};

export default AppBar;
