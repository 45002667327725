import React from 'react';
import { TextField as MuiTextField } from '@material-ui/core';
import { fieldToTextField, TextFieldProps } from 'formik-material-ui';
import useStyles from './styles';

const FormikTextField = (props: TextFieldProps) => {
    const classes = useStyles();

    return (
        <MuiTextField
            {...fieldToTextField(props)}
            variant={'outlined'}
            InputProps={{
                classes: {
                    root: classes.root,
                    disabled: classes.disabled,
                },
            }}
        />
    );
};

export default FormikTextField;
