import { createStyles, makeStyles, Theme } from '@material-ui/core';
import { ASPECT_RATIOS } from 'config/Media';

const styles = (theme: Theme) =>
    createStyles({
        imageWithRespectedAspectRatio: {
            width: '100%',
            height: 'auto',
            objectFit: 'cover',
        },
        imageWithFixedAspectRatio: {
            position: 'absolute',
            objectFit: 'cover',
            top: 0,
            left: 0,
            bottom: 0,
            right: 0,
            width: '100%',
            height: '100%',
        },
    });

export default makeStyles(styles);
