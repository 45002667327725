import { Theme, makeStyles, createStyles } from '@material-ui/core';

const styles = (theme: Theme) =>
    createStyles({
        cardLink: {
            color: theme.palette.primary.main,
            fontFamily: `'Chivo', sans-serif`,
            fontWeight: 'bold',
            textDecoration: 'none',
        },
        footerLink: {
            color: theme.palette.custom.darkGrey,
            '&:hover': {
                color: theme.palette.primary.main,
            },
        },
        nakedLink: {
            textDecoration: 'underline',
        },
    });

export default makeStyles(styles);
