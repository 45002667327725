import { Theme, makeStyles, createStyles } from '@material-ui/core';

const styles = (theme: Theme) =>
    createStyles({
        modalProps: {
            backgroundColor: 'rgba(0, 0, 0, 0.1)',
        },
        paper: {
            width: '100%',
            backgroundColor: theme.palette.background.default,
        },
        header: {
            padding: theme.spacing(3, 2, 10, 2),
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
        },
        closeButton: {
            backgroundColor: 'rgba(255,255,255, 0.16)',
        },
    });

export default makeStyles(styles);
