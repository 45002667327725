import { Theme, makeStyles, createStyles } from '@material-ui/core';

const styles = (theme: Theme) =>
    createStyles({
        /********
         * BASE *
         ********/
        appBar: {
            backgroundColor: theme.palette.background.default,
            borderBottom: `1px solid ${theme.palette.custom.white16}`,
            padding: theme.spacing(2, 0),
        },
        root: {
            maxWidth: theme.breakpoints.values.lg,
            margin: '0px auto',
            width: '100%',
        },
        flexRowBase: {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
        },
        rowSpaceBetween: {
            justifyContent: 'space-between',
        },

        /****************************
         * APPBAR CONTENT CONTAINER *
         ****************************/
        toolbarLeft: {
            flex: 1,
            justifyContent: 'flex-start',
            marginLeft: theme.spacing(3),

            [theme.breakpoints.up('lg')]: {
                marginLeft: 0,
            },
        },
        toolbarCenter: {
            flex: 1,
            justifyContent: 'center',
        },
        toolbarRight: {
            flex: 1,
            justifyContent: 'flex-end',
            marginRight: theme.spacing(3),

            [theme.breakpoints.up('lg')]: {
                marginRight: 0,
            },
        },

        /***********
         * CONTENT *
         ***********/
        navlinkContainer: {
            padding: theme.spacing(0, 2),
        },
        mobileMenuTint: {
            color: theme.palette.primary.main,
        },
        headerNavContainer: {
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            alignItems: 'center',
            padding: theme.spacing(2, 0),
        },
    });

export default makeStyles(styles);
