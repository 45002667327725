import { Theme, makeStyles, createStyles } from '@material-ui/core';

const styles = (theme: Theme) =>
    createStyles({
        atSurfacePaper: {
            backgroundColor: theme.palette.custom.surfaces.atSurface,
        },
        aboveSurfacePaper: {
            backgroundColor: theme.palette.custom.surfaces.aboveSurface,
        },
    });

export default makeStyles(styles);
