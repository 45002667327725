import React from 'react';
import { Image } from 'types/media';
import { ZoomableImage } from 'atoms';
import Skeleton from '@material-ui/lab/Skeleton';
import useStyles from './styles';
import { CLOUDINARY_PREFIX } from 'config/Media';

const ImageMasonryGrid = (props: ImageMasonryGridPropTypes) => {
    const classes = useStyles();

    const { loading, images, albumName } = props;

    const renderSkeletonMasonryGrid = () => {
        return (
            <>
                <Skeleton
                    variant={'rect'}
                    className={classes.tallMasonrySkeletonBrickItem}
                />

                <Skeleton
                    variant={'rect'}
                    className={classes.shortMasonrySkeletonBrickItem}
                />

                <Skeleton
                    variant={'rect'}
                    className={classes.shortMasonrySkeletonBrickItem}
                />

                <Skeleton
                    variant={'rect'}
                    className={classes.tallMasonrySkeletonBrickItem}
                />

                <Skeleton
                    variant={'rect'}
                    className={classes.tallMasonrySkeletonBrickItem}
                />

                <Skeleton
                    variant={'rect'}
                    className={classes.shortMasonrySkeletonBrickItem}
                />
            </>
        );
    };

    return (
        <div className={classes.masonryGrid}>
            {loading
                ? renderSkeletonMasonryGrid()
                : images.map((image, index) => (
                      <ZoomableImage
                          key={image.url}
                          src={image.url}
                          alt={`image number ${index + 1} from album "${albumName}"`}
                          className={classes.masonryGridItem}
                      />
                  ))}
        </div>
    );
};

ImageMasonryGrid.defaultProps = {
    loading: true,
};

export type ImageMasonryGridPropTypes = {
    loading: Boolean;
    images: Array<Image>;
    albumName: string;
};

export default ImageMasonryGrid;
