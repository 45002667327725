import { Theme, makeStyles, createStyles } from '@material-ui/core';

const styles = (theme: Theme) =>
    createStyles({
        root: {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            '&:hover': {
                textDecoration: 'underline',
                cursor: 'pointer',
                color: theme.palette.primary.main,
            },
        },
        backButtonText: {
            color: theme.palette.primary.main,
        },
    });

export default makeStyles(styles);
