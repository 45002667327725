import Skeleton from '@material-ui/lab/Skeleton';
import React from 'react';
import Ticker from 'react-ticker';
import { ImageMeta } from 'types/media';
import useStyles from './styles';

const CollaboratorsScrollingTicker = (props: CollaboratorsScrollingTickerPropTypes) => {
    const { firstRowImages, secondRowImages, loading } = props;

    const classes = useStyles();

    const getImages = (collection: ImageMeta[]) => {
        return (
            <div className={classes.beltContainer}>
                {collection.map(({ imageSrc, altText }) => {
                    return (
                        <div key={altText} className={classes.scrollBoxContainer}>
                            <div className={classes.brandImageContainer}>
                                <img
                                    src={'/static/img/collaborators/' + imageSrc}
                                    alt={altText}
                                    className={classes.brandImage}
                                />
                            </div>
                        </div>
                    );
                })}
            </div>
        );
    };

    if (loading) {
        return (
            <>
                <div className={classes.scrollableRowContainer}>
                    <Skeleton variant={'rect'} height={'20vh'} width={'100%'} />
                </div>
                <div className={classes.scrollableRowContainer}>
                    <Skeleton variant={'rect'} height={'20vh'} width={'100%'} />
                </div>
            </>
        );
    } else {
        return (
            <>
                <div className={classes.scrollableRowContainer}>
                    <Ticker mode={'smooth'}>{() => getImages(firstRowImages)}</Ticker>
                </div>
                <div className={classes.scrollableRowContainer}>
                    <Ticker direction={'toRight'}>
                        {() => getImages(secondRowImages)}
                    </Ticker>
                </div>
            </>
        );
    }
};

CollaboratorsScrollingTicker.defaultProps = {
    loading: true,
};

export type CollaboratorsScrollingTickerPropTypes = {
    firstRowImages: Array<ImageMeta>;
    secondRowImages: Array<ImageMeta>;
    loading?: boolean;
};

export default CollaboratorsScrollingTicker;
