import React from 'react';
import { Box, Hidden, Typography, useMediaQuery, useTheme } from '@material-ui/core';
import { Avatar, Button, InfoChip, Paper } from 'atoms';
import Skeleton from '@material-ui/lab/Skeleton';
import { HEADSHOT, RESUME_DOWNLOAD_LINK } from 'config/Media';
import { Download } from 'react-feather';
import useStyles from './styles';

const AboutTheCreatorCard = React.forwardRef<
    HTMLDivElement,
    AboutTheCreatorCardPropTypes
>((props, forwardedRef) => {
    const { onContactClick, loading } = props;

    const classes = useStyles();

    const theme = useTheme();
    const smOnly = useMediaQuery(theme.breakpoints.only('sm'));
    const isSmDown = useMediaQuery(theme.breakpoints.down('sm'));

    /**
     * Render Name and Badges
     */
    const renderBaseInfo = () => {
        if (loading) {
            return (
                <div>
                    <Skeleton
                        height={'6vh'}
                        variant={'rect'}
                        width={isSmDown ? '60vw' : '400px'}
                    />
                    <div className={classes.creativeChipsContainer}>
                        <div className={classes.creativeChip}>
                            <Skeleton variant={'rect'} width={'100px'} height={'24px'} />
                        </div>
                        <div className={classes.creativeChip}>
                            <Skeleton variant={'rect'} width={'100px'} height={'24px'} />
                        </div>
                        <div className={classes.creativeChip}>
                            <Skeleton variant={'rect'} width={'100px'} height={'24px'} />
                        </div>
                    </div>
                </div>
            );
        } else {
            return (
                <div>
                    <Typography variant={smOnly ? 'h2' : 'h3'} component={'h1'}>
                        Hi, I’m Jena !{' '}
                        <Typography
                            component={'span'}
                            variant={'caption'}
                            color={'primary'}
                        >
                            they/them
                        </Typography>
                    </Typography>
                    <div className={classes.creativeChipsContainer}>
                        <div className={classes.creativeChip}>
                            <InfoChip
                                size={isSmDown ? 'small' : 'medium'}
                                label={'EDITOR'}
                            />
                        </div>
                        <div className={classes.creativeChip}>
                            <InfoChip
                                size={isSmDown ? 'small' : 'medium'}
                                label={'VIDEOGRAPHER'}
                            />
                        </div>
                        <div className={classes.creativeChip}>
                            <InfoChip
                                size={isSmDown ? 'small' : 'medium'}
                                label={'PHOTOGRAPHER'}
                            />
                        </div>
                    </div>
                </div>
            );
        }
    };

    const renderBio = () => {
        if (loading) {
            return (
                <div className={classes.bioContainer}>
                    <Skeleton variant={'rect'} height={'20vh'} width={'100%'} />
                </div>
            );
        }
        return (
            <div className={classes.bioContainer}>
                <Typography variant={'body1'} gutterBottom display={'block'}>
                    Jena is a visual artist based in Chicago, IL. From concept to
                    completion, Jena is a triple threat in the photo, video and graphics
                    worlds. Their goal in every project is to help clients tell their
                    story in an authentic style that is engaging to their audience and
                    unique to them.
                </Typography>
                <br />
                <Typography variant={'body1'} gutterBottom display={'block'}>
                    Outside of work, you can find Jena rocking out at a show, making
                    cocktails and hanging out with their cats.
                </Typography>
                <br />
                <Typography variant={'body1'} gutterBottom display={'block'}>
                    Thanks for stopping by!
                </Typography>
            </div>
        );
    };

    const renderAboutText = () => {
        return (
            <div className={classes.aboutContainer}>
                {renderBaseInfo()}
                {renderBio()}
            </div>
        );
    };

    const renderAvatar = () => {
        let element;
        if (loading) {
            element = (
                <div className={classes.skeletalImageContainer}>
                    <Skeleton variant={'rect'} className={classes.skelatalImage} />
                </div>
            );
        } else {
            element = (
                <Avatar
                    src={HEADSHOT}
                    alt={'Jena Snelling Avatar'}
                    disableAnimation={isSmDown}
                />
            );
        }

        return (
            <div ref={forwardedRef} className={classes.avatarContainer}>
                {element}
            </div>
        );
    };

    const renderLayout = () => {
        if (isSmDown) {
            return (
                <>
                    <div className={classes.mobileAboutLayout}>
                        {renderAvatar()}

                        {renderBaseInfo()}
                    </div>
                    {renderBio()}
                </>
            );
        } else {
            return (
                <>
                    {renderAvatar()}
                    {renderAboutText()}
                </>
            );
        }
    };

    const renderActions = (direction: 'row' | 'column') => {
        const boxProps: any = {};

        if (direction === 'row') {
            boxProps.paddingLeft = 3;
        } else {
            boxProps.paddingTop = 3;
            boxProps.width = '100%';
        }

        if (loading) {
            return (
                <>
                    <Skeleton
                        variant={'rect'}
                        width={direction === 'column' ? '100%' : '86px'}
                        height={'40px'}
                    />

                    <Box {...boxProps}>
                        <Skeleton
                            variant={'rect'}
                            width={direction === 'column' ? '100%' : '118px'}
                            height={'40px'}
                        />
                    </Box>
                </>
            );
        } else {
            return (
                <>
                    <Button
                        variant={'contained'}
                        color={'primary'}
                        onClick={onContactClick}
                        fullWidth={direction === 'column'}
                        disabled={loading}
                    >
                        Contact
                    </Button>
                    <Box {...boxProps}>
                        <Button
                            component={'a'}
                            variant={'outlined'}
                            color={'primary'}
                            href={RESUME_DOWNLOAD_LINK}
                            download={'Jena Snelling Resume'}
                            fullWidth={direction === 'column'}
                            disabled={loading}
                        >
                            <Download className={classes.downloadIcon} /> Resume
                        </Button>
                    </Box>
                </>
            );
        }
    };

    return (
        <Paper surfaceColor={'aboveSurface'} className={classes.root}>
            <div className={classes.content}>
                {renderLayout()}
                <Hidden smDown>
                    <div className={classes.buttonGroupContainer}>
                        {renderActions('row')}
                    </div>
                </Hidden>
            </div>
            <Hidden mdUp>
                <div className={classes.buttonGroupContainer}>
                    {renderActions('column')}
                </div>
            </Hidden>
        </Paper>
    );
});

AboutTheCreatorCard.defaultProps = {
    loading: true,
};

export type AboutTheCreatorCardPropTypes = {
    onContactClick: Function;
    loading?: boolean;
};

export default AboutTheCreatorCard;
