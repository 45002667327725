import { Theme, makeStyles, createStyles } from '@material-ui/core';

const styles = (theme: Theme) =>
    createStyles({
        buttonRoot: {
            borderRadius: '4px',
        },
        buttonLabel: {
            textTransform: 'none',
            fontFamily: 'Overpass',
            fontWeight: 'bold',
        },
        containedPrimary: {
            border: `2px solid ${theme.palette.primary.main}`,
            '&:hover': {
                border: `2px solid ${theme.palette.primary.main}`,
                color: theme.palette.primary.main,
                backgroundColor: 'transparent',
            },
        },
        containedSecondary: {
            border: `2px solid ${theme.palette.secondary.main}`,
            color: theme.palette.common.white,
            '&:hover': {
                border: `2px solid ${theme.palette.secondary.main}`,
                color: theme.palette.common.white,
                backgroundColor: 'transparent',
            },
        },
        outlinedPrimary: {
            border: `2px solid ${theme.palette.primary.main}`,
            '&:hover': {
                border: `2px solid ${theme.palette.primary.main}`,
                backgroundColor: theme.palette.primary.main,
                color: theme.palette.common.black,
            },
        },
        outlinedSecondary: {
            border: `2px solid ${theme.palette.common.white}`,
            color: theme.palette.common.white,
            '&:hover': {
                border: `2px solid ${theme.palette.common.white}`,
                backgroundColor: theme.palette.common.white,
                color: theme.palette.common.black,
            },
        },
        textSecondary: {
            color: theme.palette.custom.white70,
            '&:hover': {
                backgroundColor: theme.palette.custom.white08,
            },
        },
        disabled: {
            border: 'none',
        },
    });

export default makeStyles(styles);
