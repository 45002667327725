import { Theme, makeStyles, createStyles } from '@material-ui/core';

const styles = (theme: Theme) =>
    createStyles({
        container: {
            padding: theme.spacing(4, 2),
            textDecoration: 'none',
            color: theme.palette.text.secondary,
            '&:hover': {
                backgroundColor: theme.palette.custom.white16,
                color: theme.palette.primary.main,
            },
            '&:active': {
                color: theme.palette.primary.main,
            },
        },
        listItemText: {
            color: 'inherit',
        },
        active: {
            backgroundColor: theme.palette.custom.white08,
            color: theme.palette.primary.main,
        },
    });

export default makeStyles(styles);
