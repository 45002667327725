import React from 'react';
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogProps,
    DialogTitle,
    IconButton,
    Typography,
    useMediaQuery,
    useTheme,
} from '@material-ui/core';
import { ToolTip } from 'atoms';
import { X } from 'react-feather';
import useStyles from './styles';

const ContentModal = (props: ContentModalPropTypes) => {
    const classes = useStyles();
    const {
        open,
        onClose,
        embedCode,
        title,
        description,
        children,
        onShareClick,
        ...rest
    } = props;

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const renderTitle = () => {
        return (
            <div>
                <Typography variant={'h6'} color={'primary'}>
                    {title ? title : ''}
                </Typography>
                <Typography variant={'body2'} color={'textSecondary'}>
                    {description ? description : ''}
                </Typography>
            </div>
        );
    };

    const renderDialogHeader = () => {
        return (
            <DialogTitle disableTypography className={classes.dialogeTitleContainer}>
                {renderTitle()}
                <ToolTip title={'Close'} arrow>
                    <div className={classes.closeButton}>
                        <IconButton
                            onClick={(event: any) => onClose(event, 'escapeKeyDown')}
                        >
                            <X />
                        </IconButton>
                    </div>
                </ToolTip>
            </DialogTitle>
        );
    };

    const renderMobileDialogContentController = () => {
        return (
            <div className={classes.closeButtonContainer}>
                <div className={classes.closeButton}>
                    <IconButton onClick={(event: any) => onClose(event, 'escapeKeyDown')}>
                        <X />
                    </IconButton>
                </div>
            </div>
        );
        // NOTE: May want to use this in the future
        // return (
        //     <div className={classes.mobileTitleContainer}>
        //         {renderTitle()}
        //         <div className={classes.closeButtonContainer}>
        //             <Button
        //                 onClick={onClose}
        //                 fullWidth
        //                 variant={'text'}
        //                 color={'secondary'}
        //                 className={classes.closeButtonMobile}
        //             >
        //                 Close
        //             </Button>
        //         </div>
        //     </div>
        // );
    };

    return (
        <Dialog
            open={open}
            onClose={onClose}
            aria-labelledby={`${title}-dialog`}
            maxWidth={'lg'}
            fullWidth
            PaperProps={{
                className: classes.card,
            }}
            fullScreen={isMobile}
            className={classes.dialog}
            {...rest}
        >
            {isMobile ? null : renderDialogHeader()}

            <DialogContent
                classes={{
                    root: classes.dialogContent,
                }}
            >
                {children}
                {isMobile ? renderMobileDialogContentController() : null}
            </DialogContent>
            {isMobile ? null : (
                <DialogActions
                    classes={{
                        root: classes.dialogActionsContainer,
                    }}
                >
                    {/**TODO: Comment back in when sharing capabilities are available */}
                    {/* {onShareClick ? (
                            <Button color={'primary'} variant={'text'} onClick={onShareClick}>
                                Share
                            </Button>
                        ) : null} */}
                </DialogActions>
            )}
        </Dialog>
    );
};

export interface ContentModalPropTypes extends DialogProps {
    embedCode?: string;
    description?: string;
    onShareClick?: Function;
}

export default ContentModal;
