import React from 'react';
import { Box, Typography, useMediaQuery, useTheme } from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';
import { BaseLink, Button, GridContainer, GridItem, Link } from 'atoms';
import { VideoThumbnail } from 'molecules';
import { VideoMeta } from 'types/media';
import useStyles from './styles';

const VideoTeaserGrid = (props: VideoTeaserGridPropTypes) => {
    const { videos, loading, gridTitle, seeAllHref, onClickVideoThumbnail } = props;

    const classes = useStyles();

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const renderGridHeader = () => {
        if (loading) {
            return (
                <>
                    <Skeleton variant={'text'} width={'90px'} height={'25px'} />
                    {!isMobile && seeAllHref ? (
                        <Skeleton variant={'text'} width={'70px'} height={'25px'} />
                    ) : null}
                </>
            );
        } else {
            return (
                <>
                    <Typography
                        variant={'subtitle1'}
                        className={classes.capitalizeGridTitle}
                    >
                        {gridTitle}
                    </Typography>
                    {!isMobile && seeAllHref ? (
                        <Link variant={'naked'} href={seeAllHref}>
                            See All
                        </Link>
                    ) : null}
                </>
            );
        }
    };

    const renderVideoGrid = () => {
        if (loading) {
            return (
                <>
                    <div className={classes.hideGridOverflow}>
                        <GridContainer direction={'row'} spacing={2}>
                            {[1, 2, 3, 4, 5, 6, 7, 8].map((item) => (
                                <GridItem key={item} xs={12} sm={6} md={4}>
                                    <div>
                                        <div className={classes.teaserSkeleton}>
                                            <Skeleton
                                                variant={'rect'}
                                                className={classes.skeletalImage}
                                            />
                                        </div>
                                        <Skeleton variant={'text'} />

                                        <Skeleton variant={'text'} width={'60%'} />
                                    </div>
                                </GridItem>
                            ))}
                        </GridContainer>
                    </div>
                    {seeAllHref && isMobile ? (
                        <Box paddingTop={3} width={'100%'}>
                            <Skeleton variant={'rect'} width={'100%'} height={'44px'} />
                        </Box>
                    ) : null}
                </>
            );
        } else {
            return (
                <div className={classes.hideGridOverflow}>
                    <GridContainer spacing={2}>
                        {videos.map(({ previewImage, title, desc, videoLink }) => (
                            <GridItem key={videoLink} xs={12} sm={6} md={4}>
                                <VideoThumbnail
                                    thumbnailSrc={previewImage.url}
                                    title={title}
                                    description={desc}
                                    onClick={() => {
                                        onClickVideoThumbnail(videoLink, title, desc);
                                    }}
                                />
                            </GridItem>
                        ))}
                    </GridContainer>
                    {seeAllHref && isMobile ? (
                        <Box paddingTop={3} width={'100%'}>
                            <Button
                                fullWidth
                                component={BaseLink}
                                href={seeAllHref}
                                variant={'outlined'}
                                color={'secondary'}
                                size={'large'}
                            >
                                See All
                            </Button>
                        </Box>
                    ) : null}
                </div>
            );
        }
    };

    return (
        <div className={classes.root}>
            <div className={classes.gridHeader}>{renderGridHeader()}</div>
            {renderVideoGrid()}
        </div>
    );
};

VideoTeaserGrid.defaultProps = {
    loading: true,
};

export type VideoTeaserGridPropTypes = {
    loading: boolean;
    gridTitle: string;
    seeAllHref?: string;
    videos: Array<VideoMeta>;
    onClickVideoThumbnail: Function;
};

export default VideoTeaserGrid;
