import React from 'react';
import { Paper as MuiPaper, PaperProps } from '@material-ui/core';
import useStyles from './styles';

const Paper = (props: PaperPropTypes) => {
    const { surfaceColor, ...rest } = props;
    const classes = useStyles();

    return (
        <MuiPaper
            {...rest}
            elevation={0}
            square
            classes={{
                root: classes[`${surfaceColor}Paper` as keyof typeof classes],
            }}
        />
    );
};

Paper.defaultProps = {
    surfaceColor: 'aboveSurface',
};

export interface PaperPropTypes extends PaperProps {
    surfaceColor?: 'atSurface' | 'aboveSurface';
}

export default Paper;
