import React, { useCallback, useState } from 'react';
import { ASPECT_RATIOS } from 'config/Media';
import { Controlled as ControlledZoom } from 'react-medium-image-zoom';
import clsx from 'clsx';
import useStyles from './styles';

const ZoomableImage = (props: ZoomableImagePropTypes) => {
    const { respectAspectRatio, ...rest } = props;
    const classes = useStyles();

    const [isZoomed, setIsZoomed] = useState(false);

    const handleZoomChange = useCallback((shouldZoom) => {
        setIsZoomed(shouldZoom);
    }, []);

    let wrappingStyle = {};
    if (respectAspectRatio === false) {
        wrappingStyle = {
            position: 'relative',
            overflow: 'hidden',
            paddingTop: ASPECT_RATIOS.images.teasers.standard,
            width: '100%',
        };
    }

    return (
        <ControlledZoom
            overlayBgColorEnd={'rgba(0, 0, 0, 0.9)'}
            overlayBgColorStart={'rgba(0, 0, 0, 0.9)'}
            isZoomed={isZoomed}
            onZoomChange={handleZoomChange}
            wrapStyle={wrappingStyle}
        >
            <img
                className={clsx({
                    [classes.imageWithRespectedAspectRatio]: respectAspectRatio,
                    [classes.imageWithFixedAspectRatio]: respectAspectRatio === false,
                })}
                {...rest}
            />
        </ControlledZoom>
    );
};

ZoomableImage.defaultProps = {
    respectAspectRatio: true,
};

export type ZoomableImagePropTypes = JSX.IntrinsicElements['img'] & {
    respectAspectRatio?: boolean;
};
export default ZoomableImage;
