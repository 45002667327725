import { Theme, makeStyles, createStyles } from '@material-ui/core';

const styles = (theme: Theme) =>
    createStyles({
        root: {
            height: '40px',
            width: '40px',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: theme.palette.custom.surfaces.aboveSurface,
        },
        playIcon: {
            fontSize: theme.typography.pxToRem(18),
            color: theme.palette.primary.main,
        },
    });

export default makeStyles(styles);
