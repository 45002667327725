import React from 'react';
import { Typography } from '@material-ui/core';
import { BrandLogo, CopyrightNotice, Paper } from 'atoms';
import { FOOTER_LINKS, SOCIAL_MEDIA_LINKS } from 'config/Nav';
import { FooterLinkGroup, SocialMediaGroup } from 'molecules';
import useStyles from './styles';

const Footer = (props: FooterPropTypes) => {
    const classes = useStyles();

    return (
        <Paper
            component={'footer'}
            surfaceColor={'aboveSurface'}
            className={classes.paperContainer}
        >
            <div className={classes.footerContentContainer}>
                <div className={classes.siteLinksContainer}>
                    <BrandLogo variant={'static'} size={'large'} asLink />
                    <div className={classes.sectionContainer}>
                        <FooterLinkGroup groupHeader={'Site'} links={FOOTER_LINKS.SITE} />
                    </div>
                    <div className={classes.sectionContainer}>
                        <Typography variant={'subtitle1'} color={'textPrimary'}>
                            Follow me
                        </Typography>
                        <SocialMediaGroup socials={SOCIAL_MEDIA_LINKS} />
                    </div>
                </div>
                <div className={classes.copyrightNoticeContainer}>
                    <CopyrightNotice />
                </div>
            </div>
        </Paper>
    );
};

export type FooterPropTypes = {};

export default Footer;
