import { Theme, makeStyles, createStyles } from '@material-ui/core';

const styles = (theme: Theme) =>
    createStyles({
        paperContainer: {
            width: '100%',
            display: 'flex',
            flexDirection: 'row',
            paddingTop: '4rem',
            paddingBottom: '1rem',
        },
        footerContentContainer: {
            maxWidth: theme.breakpoints.values.lg,
            width: '100%',
            margin: '0 auto',
            [theme.breakpoints.down('md')]: {
                margin: theme.spacing(0, 2),
            },
        },
        siteLinksContainer: {
            width: '100%',
            display: 'flex',
            flexDirection: 'row',
            [theme.breakpoints.down('sm')]: {
                flexDirection: 'column',
            },
        },
        copyrightNoticeContainer: {
            paddingTop: '6rem',
            margin: '0 auto',
        },
        sectionContainer: {
            padding: theme.spacing(0, 8),
            [theme.breakpoints.down('sm')]: {
                padding: theme.spacing(3, 0),
            },
        },
    });

export default makeStyles(styles);
