import React from 'react';
import { useMediaQuery, useTheme } from '@material-ui/core';
import { SocialMediaLink as SocialMediaLinkIcon } from 'atoms';
import { SocialMediaLink } from 'types/socialMedia';
import useStyles from './styles';

const SocialMediaGroup = (props: SocialMediaGroupPropTypes) => {
    const { socials } = props;
    const classes = useStyles();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    return (
        <div className={classes.root}>
            {socials.map((socialMediaLink) => (
                <div
                    key={`${socialMediaLink.variant}Link`}
                    className={classes.iconContainer}
                >
                    <SocialMediaLinkIcon
                        variant={socialMediaLink.variant}
                        href={socialMediaLink.href}
                        iconSize={isMobile ? 'medium' : 'large'}
                    />
                </div>
            ))}
        </div>
    );
};

export type SocialMediaGroupPropTypes = {
    socials: Array<SocialMediaLink>;
};

export default SocialMediaGroup;
