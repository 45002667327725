import React, { MouseEvent } from 'react';
import NextImg from 'next/image';
import { Fade, Typography, useMediaQuery, useTheme } from '@material-ui/core';
import { truncate } from 'lodash';
import clsx from 'clsx';
import useStyles from './styles';

const ImageThumbnail = (props: ImageThumbnailPropTypes) => {
    const classes = useStyles();

    const { thumbnailSrc, onClick, title, description } = props;

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const [showContentDescription, setShowContentDescription] = React.useState(false);

    React.useEffect(() => {
        setShowContentDescription(isMobile);
    }, [isMobile]);

    const renderContentDescription = () => {
        return (
            <>
                {title ? (
                    <Typography
                        variant={'subtitle1'}
                        color={'primary'}
                        className={classes.videoTitle}
                    >
                        {title}
                    </Typography>
                ) : null}
                {description ? (
                    <Typography
                        variant={'body2'}
                        color={'textSecondary'}
                        className={classes.videoDescription}
                    >
                        {truncate(description, {
                            length: 70,
                        })}
                    </Typography>
                ) : null}
            </>
        );
    };

    const handleOnMouseEnter = () => {
        setShowContentDescription(true);
    };

    const handleOnMouseLeave = () => {
        setShowContentDescription(false);
    };

    return (
        <div
            onMouseEnter={isMobile ? null : handleOnMouseEnter}
            onMouseLeave={isMobile ? null : handleOnMouseLeave}
            onClick={onClick}
            className={classes.root}
            tabIndex={0}
        >
            <div className={clsx(classes.container)}>
                <NextImg
                    src={thumbnailSrc}
                    className={classes.thumbnailImage}
                    alt={title}
                    layout={'fill'}
                />
                {/**
                 * Only show blur-hover interaction if we are not mobile and tracked via state
                 */}
                {showContentDescription ? (
                    <Fade in={showContentDescription}>
                        <div className={classes.contentDescriptionContainer}>
                            {renderContentDescription()}
                        </div>
                    </Fade>
                ) : null}
            </div>
        </div>
    );
};

ImageThumbnail.defaultProps = {
    title: 'Click to see more',
};

export type ImageThumbnailPropTypes = {
    onClick?: (event: MouseEvent<HTMLDivElement>) => void;
    thumbnailSrc: string;
    title?: string;
    description?: string;
};

export default ImageThumbnail;
