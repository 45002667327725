import { createStyles, makeStyles, Theme } from '@material-ui/core';

const styles = (theme: Theme) =>
    createStyles({
        root: {
            width: '100%',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
        },
        heightFirst: {
            height: '600px',
        },
        addZoomIn: {
            '&:hover': {
                cursor: 'zoom-in',
            },
        },
        addZoomOut: {
            '&:hover': {
                cursor: 'zoom-out',
            },
        },
        image: {
            objectFit: 'contain',
        },
    });

export default makeStyles(styles);
