import { Theme, makeStyles, createStyles } from '@material-ui/core';

const styles = (theme: Theme) =>
    createStyles({
        root: {
            backgroundColor: theme.palette.common.black,
            position: 'relative',
            paddingTop: '100%',
        },
        avatar: {
            borderRadius: 0,
            position: 'absolute',
            top: 0,
            bottom: 0,
            right: 0,
            left: 0,
            width: '100%',
            height: '100%',
        },
        animateAvatar: {
            animation: '$displaceImage 0.5s forwards',
        },
        '@keyframes displaceImage': {
            from: {
                transform: 'none',
            },
            to: {
                transform: `translate(-${theme.spacing(2)}px, -${theme.spacing(2)}px)`,
            },
        },
    });

export default makeStyles(styles);
