import { Theme, makeStyles, createStyles } from '@material-ui/core';

const styles = (theme: Theme) =>
    createStyles({
        root: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
        },
        linkGroupItem: {
            '&:not(:last-child)': {
                paddingBottom: theme.spacing(2),
            },
        },
        linkGroupHeader: {
            paddingBottom: theme.spacing(2),
        },
    });

export default makeStyles(styles);
