import { Theme, makeStyles, createStyles } from '@material-ui/core';

const styles = (theme: Theme) =>
    createStyles({
        root: {
            padding: theme.spacing(7),
            [theme.breakpoints.down('sm')]: {
                padding: theme.spacing(2, 2, 5, 2),
            },
            width: '100%',
        },
        mobileAboutLayout: {
            display: 'flex',
            flexDirection: 'row',
        },
        content: {
            width: '100%',
            maxWidth: theme.breakpoints.values.lg,
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-start',
            [theme.breakpoints.down('sm')]: {
                flexDirection: 'column',
            },
            margin: '0 auto',
        },
        creativeChipsContainer: {
            display: 'flex',
            flexDirection: 'row',
            justContent: 'flex-start',
            alignItems: 'center',
            flexWrap: 'wrap',
        },
        creativeChip: {
            paddingTop: theme.spacing(1),
            '&:not(:last-child)': {
                paddingRight: theme.spacing(2),
            },
            [theme.breakpoints.down('sm')]: {
                '&:not(:last-child)': {
                    paddingRight: theme.spacing(1),
                },
            },
        },
        avatarContainer: {
            paddingRight: theme.spacing(4),
            width: '35%',
            minWidth: '90px',
            [theme.breakpoints.down('sm')]: {
                maxWidth: '150px',
                paddingRight: theme.spacing(2),
                width: '50%',
            },
        },
        skeletalImageContainer: {
            position: 'relative',
            paddingTop: '100%',
        },
        skelatalImage: {
            borderRadius: 0,
            position: 'absolute',
            top: 0,
            bottom: 0,
            right: 0,
            left: 0,
            width: '100%',
            height: '100%',
        },
        aboutContainer: {
            width: '100%',
            paddingRight: theme.spacing(4),
            [theme.breakpoints.down('sm')]: {
                paddingRight: 0,
            },
        },
        bioContainer: {
            paddingTop: theme.spacing(5),
            [theme.breakpoints.down('sm')]: {
                padding: theme.spacing(5, 0),
            },
        },
        buttonGroupContainer: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-end',
            alignItems: 'flex-start',
            [theme.breakpoints.down('sm')]: {
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
            },
        },
        downloadIcon: {
            fontSize: theme.typography.pxToRem(10),
            marginRight: theme.spacing(1),
        },
    });

export default makeStyles(styles);
