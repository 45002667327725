import { SocialMediaLink } from 'types/socialMedia';

export const LOGIN_URI = `https://${process.env.auth0Domain}/authorize?client_id=${process.env.auth0ClientId}&response_type=code&redirect_uri=${process.env.auth0Redirect}&scope=openid%20offline_access`;

export const ROUTES = {
    HOME: {
        to: '/',
        name: 'Home',
        external: false,
    },
    FILM: {
        to: '/filmography',
        name: 'Filmography',
        external: false,
    },
    PHOTO: {
        to: '/photography',
        name: 'Photography',
        external: false,
    },
    COLLAB: {
        to: '/collaboration',
        name: 'Collaboration',
        external: false,
    },
};

export const FOOTER_LINKS: any = {
    SITE: [...Object.values(ROUTES)],
};

export const SOCIAL_MEDIA_LINKS: SocialMediaLink[] = [
    {
        variant: 'instagram',
        href: 'https://www.instagram.com/jenasnelling/',
    },
    {
        variant: 'facebook',
        href: 'https://www.facebook.com/jena.snelling',
    },
    {
        variant: 'vimeo',
        href: 'https://vimeo.com/jenasnelling',
    },
    {
        variant: 'youtube',
        href: 'https://www.youtube.com/channel/UCWCBDHFPG4p9uwrvN2uMeDA',
    },
    {
        variant: 'soundcloud',
        href: 'https://soundcloud.com/jena-snelling',
    },
    {
        variant: 'linkedin',
        href: 'https://www.linkedin.com/in/jena-snelling-86b94a139/',
    },
];

export const NAV_LINKS = [ROUTES.HOME];
