import React, { MouseEvent } from 'react';
import { IconButton, SwipeableDrawer, SwipeableDrawerProps } from '@material-ui/core';
import { BrandLogo, DrawerListItem } from 'atoms';
import { ROUTES } from 'config/Nav';
import { Camera, Film, Home, Users, X } from 'react-feather';
import useStyles from './styles';

const Drawer = (props: DrawerPropTypes) => {
    const classes = useStyles();

    const { anchor, onMenuCloseClick, onClose, ...rest } = props;

    const renderCloseButton = () => {
        return (
            <IconButton
                size={'medium'}
                onClick={onMenuCloseClick}
                className={classes.closeButton}
            >
                <X color={'white'} size={24} />
            </IconButton>
        );
    };

    return (
        <SwipeableDrawer
            anchor={'right'}
            onClose={onClose}
            {...rest}
            classes={{
                modal: classes.modalProps,
                paper: classes.paper,
            }}
        >
            <div className={classes.header}>
                <BrandLogo size={'large'} asLink />
                {renderCloseButton()}
            </div>
            <DrawerListItem
                href={ROUTES.HOME.to}
                external={ROUTES.HOME.external}
                icon={<Home />}
                text={ROUTES.HOME.name}
                onLinkClick={onClose}
            />
            <DrawerListItem
                href={ROUTES.FILM.to}
                external={ROUTES.FILM.external}
                icon={<Film />}
                text={ROUTES.FILM.name}
                onLinkClick={onClose}
            />
            <DrawerListItem
                href={ROUTES.PHOTO.to}
                external={ROUTES.PHOTO.external}
                icon={<Camera />}
                text={ROUTES.PHOTO.name}
                onLinkClick={onClose}
            />
            <DrawerListItem
                href={ROUTES.COLLAB.to}
                external={ROUTES.COLLAB.external}
                icon={<Users />}
                text={ROUTES.COLLAB.name}
                onLinkClick={onClose}
            />
        </SwipeableDrawer>
    );
};

export type DrawerPropTypes = SwipeableDrawerProps & {
    onMenuCloseClick: (event: MouseEvent) => void;
};

export default Drawer;
