import React from 'react';
import useStyles from './styles';
import BaseLink, { BaseLinkPropTypes } from 'atoms/BaseLink/BaseLink';
import { Typography } from '@material-ui/core';

const Link = (props: LinkPropTypes) => {
    const { variant, children, external, ...linkProps } = props;

    const classes = useStyles();
    // declare dynamic classname to be class key type of classes
    // This allows us to index the classes record
    // More: https://stackoverflow.com/questions/56568423/typescript-no-index-signature-with-a-parameter-of-type-string-was-found-on-ty/56569217
    const classVariant = `${variant}Link` as keyof typeof classes;

    const getLinkVariant = () => {
        if (variant === 'wrapper') {
            if (external) {
                return <a {...linkProps}>{children}</a>;
            }
            return <BaseLink {...linkProps}>{children}</BaseLink>;
        }
        return (
            <Typography
                variant={'body1'}
                component={external ? 'a' : BaseLink}
                {...linkProps}
                className={classes[classVariant]}
            >
                {children}
            </Typography>
        );
    };

    return getLinkVariant();
};

Link.defaultProps = {
    external: false,
};

export interface LinkPropTypes extends BaseLinkPropTypes {
    variant: 'wrapper' | 'card' | 'footer' | 'naked';
    external?: boolean;
    target?: string;
}

export default Link;
