import React from 'react';
import { Button as MuiButton, ButtonProps } from '@material-ui/core';
import useStyles from './styles';

const Button = <T extends React.ElementType>(
    props: ButtonPropTypes & ButtonProps<T, { component?: T }>,
) => {
    const { children, ...rest } = props;

    const classes = useStyles();

    return (
        <MuiButton
            disableElevation={true}
            classes={{
                root: classes.buttonRoot,
                label: classes.buttonLabel,
                outlinedPrimary: classes.outlinedPrimary,
                outlinedSecondary: classes.outlinedSecondary,
                containedPrimary: classes.containedPrimary,
                containedSecondary: classes.containedSecondary,
                textSecondary: classes.textSecondary,
                disabled: classes.disabled,
            }}
            {...rest}
        >
            {children}
        </MuiButton>
    );
};

export type ButtonPropTypes = {};

export default Button;
