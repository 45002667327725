import React from 'react';
import { Box, Typography, useMediaQuery, useTheme } from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';
import { BaseLink, Button, GridContainer, GridItem, Link } from 'atoms';
import { ROUTES } from 'config/Nav';
import { ImageThumbnail } from 'molecules';
import { PhotographyAlbum } from 'types/media';
import useStyles from './styles';

const AlbumTeaserGrid = (props: AlbumTeaserGridPropTypes) => {
    const { albums, loading, gridTitle, seeAllHref } = props;

    const classes = useStyles();

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const renderGridHeader = () => {
        if (loading) {
            return (
                <>
                    <Skeleton variant={'text'} width={'90px'} height={'25px'} />
                    {!isMobile && seeAllHref ? (
                        <Skeleton variant={'text'} width={'70px'} height={'25px'} />
                    ) : null}
                </>
            );
        } else {
            return (
                <>
                    <Typography variant={'subtitle1'}>{gridTitle}</Typography>
                    {!isMobile && seeAllHref ? (
                        <Link variant={'naked'} href={seeAllHref}>
                            See All
                        </Link>
                    ) : null}
                </>
            );
        }
    };

    const renderImageGrid = () => {
        if (loading) {
            return (
                <>
                    <div className={classes.hideGridOverflow}>
                        <GridContainer direction={'row'} spacing={2}>
                            {[1, 2, 3, 4, 5, 6, 7, 8].map((item) => (
                                <GridItem key={item} xs={12} sm={6} md={4}>
                                    <div className={classes.teaserSkeleton}>
                                        <Skeleton
                                            variant={'rect'}
                                            className={classes.skeletalImage}
                                        />
                                    </div>
                                </GridItem>
                            ))}
                        </GridContainer>
                    </div>
                    {seeAllHref && isMobile ? (
                        <Box paddingTop={3} width={'100%'}>
                            <Skeleton variant={'rect'} width={'100%'} height={'44px'} />
                        </Box>
                    ) : null}
                </>
            );
        } else {
            return (
                <div className={classes.hideGridOverflow}>
                    <GridContainer spacing={2}>
                        {albums.map(({ heroImage, title, desc, key }: any, index) => (
                            <GridItem
                                key={`${title.replace(' ', '')}-${index}`}
                                xs={12}
                                sm={6}
                                md={4}
                            >
                                <Link
                                    variant={'wrapper'}
                                    href={ROUTES.PHOTO.to + `/${key}`}
                                >
                                    <ImageThumbnail
                                        thumbnailSrc={heroImage.url}
                                        title={title}
                                        description={desc}
                                    />
                                </Link>
                            </GridItem>
                        ))}
                    </GridContainer>
                    {seeAllHref && isMobile ? (
                        <Box paddingTop={3} width={'100%'}>
                            <Button
                                fullWidth
                                component={BaseLink}
                                href={seeAllHref}
                                variant={'outlined'}
                                color={'secondary'}
                                size={'large'}
                            >
                                See All
                            </Button>
                        </Box>
                    ) : null}
                </div>
            );
        }
    };

    return (
        <div className={classes.root}>
            <div className={classes.gridHeader}>{renderGridHeader()}</div>
            {renderImageGrid()}
        </div>
    );
};

AlbumTeaserGrid.defaultProps = {
    loading: true,
};

export type AlbumTeaserGridPropTypes = {
    loading: boolean;
    gridTitle: string;
    seeAllHref?: string;
    albums: Array<PhotographyAlbum>;
};

export default AlbumTeaserGrid;
