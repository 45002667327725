import React from 'react';
import { CircularProgress, DialogActions } from '@material-ui/core';
import { Button, FormikTextField } from 'atoms';
import { Field, Form, FormikProps } from 'formik';
import { ContactFormValues } from './ContactDialog';
import useStyles from './styles';

const ContactForm = (props: ContactFormPropTypes) => {
    const classes = useStyles();

    const { handleDialogClose, submitForm, isSubmitting } = props;

    return (
        <>
            <Form className={classes.formContainer}>
                <Field
                    component={FormikTextField}
                    name={'name'}
                    label={'Full Name'}
                    required
                />

                <Field
                    component={FormikTextField}
                    name={'email'}
                    type={'email'}
                    label={'Email'}
                    required
                />
                <Field
                    component={FormikTextField}
                    name={'subject'}
                    label={'Subject'}
                    required
                />
                <Field
                    component={FormikTextField}
                    name={'body'}
                    multiline
                    label={'Body'}
                    rows={10}
                    required
                />
            </Form>

            <DialogActions
                classes={{
                    root: classes.dialogActionsContainer,
                }}
            >
                <Button
                    disabled={isSubmitting}
                    color={'secondary'}
                    onClick={handleDialogClose}
                    variant={'text'}
                >
                    Cancel
                </Button>
                <Button
                    disabled={isSubmitting}
                    color={'primary'}
                    variant={'contained'}
                    onClick={submitForm}
                >
                    {isSubmitting ? (
                        <>
                            <div className={classes.sendingButtonTextContainer}>
                                Sending
                                <CircularProgress
                                    className={classes.circularProgress}
                                    size={16}
                                />
                            </div>
                        </>
                    ) : (
                        'Send'
                    )}
                </Button>
            </DialogActions>
        </>
    );
};

export interface ContactFormPropTypes extends FormikProps<ContactFormValues> {
    handleDialogClose: Function;
}

export default ContactForm;
