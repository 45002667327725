import { Theme, makeStyles, createStyles } from '@material-ui/core';

const styles = (theme: Theme) =>
    createStyles({
        small: {
            height: '50px',
            width: 'auto',
        },
        medium: {
            height: '80px',
            width: 'auto',
        },
        large: {
            height: '120px',
            width: 'auto',
        },
    });

export default makeStyles(styles);
