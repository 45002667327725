import { Theme, makeStyles, createStyles } from '@material-ui/core';

const styles = (theme: Theme) =>
    createStyles({
        root: {
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'wrap',
        },
        iconContainer: {
            '&:not(:last-child)': {
                paddingRight: theme.spacing(1),
            },
            [theme.breakpoints.down('sm')]: {
                '&:not(:last-child)': {
                    paddingRight: theme.spacing(0.5),
                },
            },
        },
    });

export default makeStyles(styles);
